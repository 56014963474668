import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {ICruise} from "../../types/IAuth";
import {BASE_API_URL} from "../../configs/api.config";

export interface ILogin {
    login: string,
    password: string
}

export interface ILoginData {
    token: string
    expiration: string,
    isVip: false
}

export const authSlice = createApi({
    reducerPath: "auth",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_API_URL,
        prepareHeaders: (headers ) => {
            const token = localStorage.getItem("token");
            if (token) {
                headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
            }
            return headers;
        },
    }),
    endpoints(builder) {
        return {
            login: builder.mutation<ILoginData, ILogin>({
                query: (body) => ({
                    url: `/api/Authorization/Login`,
                    method: 'POST',
                    body
                }),

            }),
            getCruise: builder.query<ICruise[] , string>({
                query: (shipId) => `/api/Cruise/GetAll/${shipId}`
            }),

            getRelativeCruise: builder.query<ICruise[] , string>({
                query: (cruiseId) => `/api/Cruise/GetRelative/${cruiseId}`
            }),

            getAllShip: builder.query({
                query: () => `/api/Ship/GetAll`
            })
        };
    }
});

export const {
    useLoginMutation,
    useGetCruiseQuery,
    useGetAllShipQuery,
    useGetRelativeCruiseQuery,
} = authSlice;