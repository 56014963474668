import React, {FunctionComponent, useEffect} from 'react';
import {LayoutProps} from "./Layout.props";
import {links, routes} from "../routes/routes";
import style from './Layout.module.css';
import HeaderContent from "./HeaderContent/HeaderContent";
import NavbarContent from "./NavbarContent/NavbarContent";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Auth from "../pages/Auth/Auth";

const Layout = ({children}: LayoutProps): JSX.Element => {

    const {pathname} = useLocation();
    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    useEffect(() => {
        if (!token){
            return navigate("/");
        }
    },[token]);

    if (pathname.split('/')[1]  === "") {
        return (
            <Routes>
                <Route path="/" element={<Auth />}/>
            </Routes>
        );
    }

    return (
        <div className={style.wrapper}>
            <HeaderContent className={style.header}/>
            <NavbarContent links={links} className={style.navbar}/>
            <div className={style.body}>
                {children}
            </div>
        </div>
    );
};

export const withLayout = <T extends Record<string, unknown>>(Component: FunctionComponent<T>) => (props: T) => (
    <Layout>
        <Component {...props}/>
    </Layout>
);