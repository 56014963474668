import {ReactComponent as RequestIcon} from "../assets/svg/applicationsIcon.svg";
import {ReactComponent as ChatIcon} from "../assets/svg/chatIcon.svg";
import {ReactComponent as RegIcon} from "../assets/svg/regIcon.svg";
import {ReactComponent as SaunaIcon} from "../assets/svg/saunaIcon.svg";
import {ReactComponent as CruiseIcon} from "../assets/svg/cruiseIcon.svg";
import {ReactComponent as ReviewsIcon} from "../assets/svg/reviews.svg";
import {ReactComponent as ExcursionIcon} from "../assets/svg/excursionIcon.svg";
import {Request, Registration, Sauna, CruiseControl, Reviews, Excursion} from "../pages";

export const routes = [
    {
        path: "request",
        component:<Request/>
    },
    {
        path: "registration",
        component:<Registration/>
    },
    {

        path: "sauna",
        component:<Sauna />
    },
    {
        path: "cruiseControl",
        component:<CruiseControl />
    },
    {
        path: "review",
        component:<Reviews/>
    },
    {
        path: "excursion",
        component:<Excursion />
    }
];


export const links = [
    {
        title: "Заявки",
        path: "request",
        icon: <RequestIcon/>,
    },
    {
        title: "Чат",
        path: "chat",
        icon: <ChatIcon/>,
    },
    {
        title: "Регистрация в ресторане",
        path: "registration",
        icon: <RegIcon/>,
    },
    {
        title: "Запись в сауну",
        path: "sauna",
        icon: <SaunaIcon/>,
    },
    {
        title: "Управление круизом",
        path: "cruiseControl",
        icon: <CruiseIcon/>,
    },
    {
        title: "Отзывы",
        path: "review",
        icon: <ReviewsIcon/>,
    },
    {
        title: "Экскурсии",
        path: "excursion",
        icon: <ExcursionIcon/>,
    },
];
