import React, {Fragment, useState} from 'react';
import {Tabs, Text, Image, Group, ActionIcon} from "@mantine/core";
import style from "../RestaurantsAndBar.module.css";
import {UploadDropzone} from "../../../../common";
import {
    useAddImageToRestaurantMutation,
    useAddMainImageToRestaurantMutation,
    useGetRestaurantImagesQuery,
    useDeleteImageToRestaurantMutation
} from "../../../../redux/apiSlice/_restaurantSlice";
import {ReactComponent as DeleteIcon} from "../../../../assets/svg/closeIcon.svg";


const Restaurant = ({currentRestaurant}: any) => {

    const {data: images} = useGetRestaurantImagesQuery(currentRestaurant);

    const [addMainImg] = useAddMainImageToRestaurantMutation();
    const [addImg] = useAddImageToRestaurantMutation();
    const [deleteImg] = useDeleteImageToRestaurantMutation();

    const setMainImg = async (file: any) => {
        const formData = new FormData();
        formData.append('file', file[0]);
        await addMainImg({
            body: formData,
            restaurantId: currentRestaurant,
        });
    };

    const setImg = async (files: any) => {
        const formData = new FormData();
        files.forEach((f: string | Blob) => {
            formData.append('files', f);
        });
        await addImg({
            body: formData,
            restaurantId: currentRestaurant,
        });
    };


    return (
        <Fragment>
            {currentRestaurant && (
                <Fragment>
                    <Text className={style.title}>Фото на главной</Text>
                    <Group direction="row">
                        <UploadDropzone setImg={setMainImg}/>
                            {images?.mainImage.uri && <div style={{width: 200, height: 175}}>
                                <Image
                                    radius="md"
                                    height={175}
                                    src={images?.mainImage.uri}
                                />
                            </div>}
                    </Group>
                    <Text className={style.title}>Фото ресторана</Text>
                    <Group direction="row">
                        <UploadDropzone setImg={setImg}/>
                        {images?.images.map((img: {id: string, uri: string}, index: number) => (
                            <div style={{display: "flex"}} key={index}>
                                <Image
                                    radius="md"
                                    height={175}
                                    width={200}
                                    src={img.uri}
                                />
                                <ActionIcon style={{ right: 25 }} onClick={() => deleteImg({body: {restaurantId: currentRestaurant, imageId: img.id}})}>
                                    <DeleteIcon style={{ fill: "#FF0A0A" }}/>
                                </ActionIcon>
                            </div>
                        ))}
                    </Group>
                </Fragment>
            )}
        </Fragment>
    );
};

export default Restaurant;
