declare const window: any;

const apiUrls = {
  land: "http://admin.sozvezdie-land03.ddp-dev.ru",
  test: "https://admin.sozvezdie-ladn03.ddp-dev.ru",
  ship02: window.config?.server,
  ship01: "https://admin.sozvezdie-ship01.ddp-dev.ru",
};

export const BASE_API_URL = apiUrls["ship02"];
export const SHIP = window.config?.ship;