import React, {useEffect, useState} from 'react';
import { useForm } from '@mantine/form';
import {SaunaProps} from "./_Sauna.props";
import style from './_Sauna.module.css';
import {ActionIcon, Group, Pagination, Table, Text} from "@mantine/core";
import {ButtonCustom} from "../../components";
import {ReactComponent as AddIcon} from "../../assets/svg/add.svg";
import {ReactComponent as FilterIcon} from '../../assets/svg/filterIcon.svg';
import 'dayjs/locale/ru';
import {Calendar} from '@mantine/dates';
import Range from "./Range/Range";
import AddSauna from "./SaunaModals/addSauna/AddSauna";
import {useGetPeriodsByCruiseIdQuery, useGetSaunaOrdersQuery} from "../../redux/apiSlice/_saunaSlice";
import dayjs from "dayjs";
import {REQUEST_DATE_TEMPLATE} from "../../constants/CONSTANT";
import {ListStub} from "../../common";
import SaunaList from "./_SaunaList";
import {ReactComponent as RefreshIcon} from "../../assets/svg/refreshIcon.svg";
import { SaunaFilterType } from '../../types/ISauna';
import SaunaFilter from './SaunaFilters/SaunaFilter';
import { useArray } from '../../hooks/hooks';

const INITIAL_FORM_VALUES = {
    passengerFullName: null,
};

export const Sauna = ({...props}: SaunaProps): JSX.Element => {
    const [opened, setOpened] = useState<boolean>(false);
    const [date, setDate] = useState<Date | null>(new Date());
    const { value: ranges, toggle: toggleRange, setValue: setRange, clear: clearRanges } = useArray<string>([]);
    const [openedDialog, setOpenedDialog] = useState(false);

    const [activePage, setPage] = useState<number>(1);
    const [pageSize, setSetPageSize] = useState<number>(10);
    const [filter, setFilter] = useState<SaunaFilterType>({});

    const CRUISE_ID = localStorage.getItem("cruise");

    const model = {
        page: activePage,
        size: pageSize,
        filter: {
            cruiseId: CRUISE_ID,
            ...filter
        }
    };

    const {data = {models: [], total: 0}, isLoading, refetch} = useGetSaunaOrdersQuery(model);

    const {
        data: periods = {
            cruiseStart: new Date(),
            cruiseEnd: new Date(),
            periods: []
        }, isLoading: isPeriodsLoading, refetch: refetchPeriods
    } = useGetPeriodsByCruiseIdQuery({cruiseId: CRUISE_ID, date: dayjs(date).format(REQUEST_DATE_TEMPLATE)});

    const [period, setPeriod] = useState<[Date, Date]>([
        new Date(periods.cruiseStart),
        new Date(periods.cruiseEnd)
    ]);

    const form = useForm<SaunaFilterType>({
        initialValues: INITIAL_FORM_VALUES,
    });
    const isFormDirty = JSON.stringify(form.values) !== JSON.stringify(INITIAL_FORM_VALUES);

    const changeDateHandler = (e: Date | null) => {
        setDate(e)
        clearRanges()
    }

    const changeRangeHandler = (range: string) => {
        const lastRange = ranges[ranges.length-1];
        const firstRange = ranges[0];
        const periodsArr = periods.periods.map(e => e.period);
        const indexLastRange = periodsArr.indexOf(lastRange);
        const indexFirstRange = periodsArr.indexOf(firstRange);
        const indexNewRange = periodsArr.indexOf(range);
        if(indexNewRange-1 === indexLastRange || indexNewRange === indexFirstRange-1 || indexLastRange === -1 || indexNewRange === indexFirstRange || indexNewRange === indexLastRange){
            toggleRange(range);
        }
        else{
            clearRanges();
            toggleRange(range);
        }
        setRange(prev => prev.sort((a:string,b:string) =>{
            const aArr = a.split(" ")[0].split(":")
            const bArr = b.split(" ")[0].split(":")
            if(aArr[1]==="30"){

                return ((parseInt(aArr[0])+0.5)-parseInt(bArr[0]))
            }
            return (parseInt(aArr[0])-(parseInt(bArr[0])+0.5))
        }))
    }

    useEffect(() => {
        setPeriod([
            new Date(periods.cruiseStart),
            new Date(periods.cruiseEnd)
        ]);
    }, [periods]);

    useEffect(() => {
        setFilter(filters => {return {...filters, date: dayjs(date).format(REQUEST_DATE_TEMPLATE)}});
    }, [date]);

    return (
        <div {...props} >
            {opened && (
                <AddSauna opened={opened}
                          ranges={ranges}
                          clearRanges={clearRanges}
                          setDate={setDate}
                          date={date}
                          onClose={() => setOpened(false)}
                          refetchPeriods={refetchPeriods}
                />
            )}
            <header className={style.header}>
                <Group>
                    <Text weight={500} size="xl" className={style.headerTitle}>
                        Запись в сауну
                    </Text>
                    <ActionIcon size={25} onClick={() => refetch()}>
                        <RefreshIcon/>
                    </ActionIcon>
                </Group>
                <Group noWrap direction="row" position="right">
                    <ButtonCustom width="sm" leftIcon={<FilterIcon className={isFormDirty ? style.buttonIcon : ""}/>}
                        appearance={isFormDirty ? "orange" : "ghost"} onClick={() => setOpenedDialog(prev => !prev)}>
                            Фильтр
                    </ButtonCustom>
                    <SaunaFilter onClose={() => setOpenedDialog(false)}
                                   opened={openedDialog}
                                   setSearch={setFilter}
                                   setPage={setPage}
                                   form={form}
                    />
                    <ButtonCustom
                        width={"lg"}
                        appearance="secondary"
                        leftIcon={<AddIcon fill="white"/>}
                        disabled={!ranges.length}
                        onClick={() => setOpened(true)}
                    >
                        Добавить запись
                    </ButtonCustom>
                </Group>
            </header>
            <Group noWrap spacing={16} align={"flex-start"} position="center">
                {data.models.length === 0 || isLoading
                    ? <ListStub isLoading={isLoading}>Нет записи</ListStub>
                    : <div style={{width: "100%"}}>
                        <Table className={style.saunaTable}>
                            <tr>
                                <Table horizontalSpacing="md" verticalSpacing="md">
                                    <thead className={style.saunaTableTHead}>
                                    <tr className={style.saunaTableTHeadRow}>
                                        <th>ФИО</th>
                                        <th>Номер каюты</th>
                                        <th>Кол-во человек</th>
                                        <th>Скидка</th>
                                        <th>Время</th>
                                        <th>Статус</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody className={style.saunaTableTbody}>
                                    {data.models.map(passenger => (
                                        <SaunaList key={passenger.passengerReuqestId} passenger={passenger}/>
                                    ))}
                                    </tbody>
                                </Table>
                            </tr>
                        </Table>
                        {data.total > pageSize && (
                            <div className={style.paginationWrap}>
                                <Pagination position="right"
                                            page={activePage}
                                            onChange={setPage}
                                            total={Math.ceil(data.total / pageSize)}
                                            color="dark"
                                            size="xs"
                                />
                            </div>
                        )}
                    </div>
                }
                <Group direction="row" className={style.sectionRightWrap}>
                    <Group direction={"column"} spacing={16} className={style.sectionCalendarWrap}>
                        <Calendar
                            size={"sm"}
                            maxDate={new Date(periods.cruiseEnd)}
                            minDate={new Date(periods.cruiseStart)}
                            className={style.programDayCalendar}
                            dayStyle={(day) =>
                                day.getDate() === date?.getDate() && day.getMonth() === date?.getMonth()
                                    ? {
                                        color: "var(--orange)",
                                        backgroundColor: "var(--lightWhite)",
                                        border: "1px solid var(--orange)",
                                        borderRadius: 4,
                                    }
                                    : {}
                            }
                            value={date}
                            onChange={(e) => changeDateHandler(e)}
                            locale="ru"
                            range={period}
                        />
                    </Group>
                    <Range periods={periods.periods}
                           isLoading={isPeriodsLoading}
                           ranges={ranges}
                           toggleRange={changeRangeHandler}
                    />
                </Group>
            </Group>
        </div>
    );
};
