import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import {BASE_API_URL} from "../../configs/api.config";
import {IDayFile} from "../../types/ICruiseControl";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers ) => {
        const token = localStorage.getItem("token");

        if (token) {
            headers.set('X-SOZVEZDIE-AUTH-TOKEN', `${token}`);
        }

        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    }
  return result;
};

export const dayScheduleSlice = createApi({
    reducerPath: "daySchedule",
    baseQuery: baseQueryWithReauth,

    tagTypes: ['File'],
    endpoints(builder) {
        return {
            getDayScheduleByDate: builder.query<IDayFile[], {date:Date | string,  cruiseId: string | null}>({
                query: ({date, cruiseId}) =>  `/api/DayScheduleFile/GetDaySchedulesByDate/${date}/${cruiseId}`,
                providesTags: (result) =>
                    result
                        ?
                        [
                            ...result.map(({ id }) => ({ type: 'File', id } as const)),
                            { type: 'File', id: 'LIST' },
                        ]
                        :
                        [{ type: 'File', id: 'LIST' }],
            }),
            DeleteDaySchedule: builder.mutation({
                query: ({dayScheduleFileId}) => ({
                    url: `/api/DayScheduleFile/DeleteDayScheduleById/${dayScheduleFileId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['File']
            }),
            addDayScheduleFile: builder.mutation({
                query: ({body, dateTime, cruiseId}) => ({
                    url: `/api/DayScheduleFile/AddDayScheduleFile/${dateTime}/${cruiseId}`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['File']
            }),
        };
    }
});

export const {
    useGetDayScheduleByDateQuery,
    useDeleteDayScheduleMutation,
    useAddDayScheduleFileMutation
} = dayScheduleSlice;