import React, {useEffect, useState} from 'react';
import {ActionIcon, Group, Loader, LoadingOverlay, Select} from "@mantine/core";
import style from "./Tablse.module.css";
import {ReactComponent as ArrowIcon} from "../../../../assets/svg/arrow.svg";
import {Trash} from "tabler-icons-react";
import {ButtonCustom} from "../../../../components";
import {formList, useForm} from "@mantine/form";
import {IActiveTable, ITables, RegistrationRestaurantType} from "../../../../types/IRegistration";
import {
    useAddTableMutation,
    useGetSeatSchemaQuery,
    useUpdateTablesMutation
} from "../../../../redux/apiSlice/_registrationSlice";
import Schemes from "../Schemes";
import {drawSeats} from "../helpers/drawSeats";

const initialTable: ITables = {
    number: 0,
    numberSeats: 0,
    isCloseRegistration: "",
    numberOccupied: 0
};

export interface ITablesProps {
    restaurantData: RegistrationRestaurantType
    onClose: () => void
}

const Tables = ({restaurantData, onClose}: ITablesProps) => {

    const restaurant = restaurantData.map((r: any) => ({value: String(r.id), label: r.name}));

    const smallTableCapacity = ["1", "2", "3", "4", "5", "6", "7", "8"]
    const wideTableCapacity = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

    const [restaurantGuid, setRestaurantGuid] = useState<string | null>("");

    const [prevActiveTable, setPrevActiveTable] = useState<IActiveTable>({numberOfTable: "0"});
    const [activeTable, setActiveTable] = useState<IActiveTable>({numberOfTable: "0"});
    const [type, setType] = useState<string | null>("");
    const [numberSeats, setNumberSeats] = useState<string>("0");
    const [numberOfNewTables, setNumberOfNewTables] = useState<number>(1);
    const [tables, setTables] = useState<IActiveTable[]>([{numberOfTable: "0"}])
    const [isDisableButton, setDisableButton] = useState<boolean>(true)

    const CRUISE_ID = localStorage.getItem("cruise");
    const [add] = useAddTableMutation();
    const [update] = useUpdateTablesMutation();
    const {
        data = {
            schemaSvg: "",
            tables: [initialTable]
        }, isLoading
    } = useGetSeatSchemaQuery({restaurantGuid, cruiseId: CRUISE_ID});


    const {reset, addListItem, removeListItem, getListInputProps, values, onSubmit} = useForm({
        initialValues: {
            restaurantId: restaurantGuid,
            tablesToAdd: formList([initialTable])
        }
    });

    const changeRestrauntHandler = (restrauntGuid: string | null) => {
        setRestaurantGuid(restrauntGuid);
        setActiveTable({numberOfTable: "0"})
        setTables([{numberOfTable: "0"}])
        if(numberOfNewTables !== 1){
            for(let i = numberOfNewTables-1; i>=1;i--){
                removeListItem('tablesToAdd', i)
            }
            setNumberOfNewTables(1);
        }
        reset();
    }
    const deleteTable = (index: number, numberTable: string) => {
        removeListItem('tablesToAdd', index);
        const table = data.tables.find(t => t.number === Number(numberTable));
        drawSeats({
            table: {
                ...table,
                number: Number(numberTable),
            },
            setActiveTable,
            setSeats: setNumberSeats,
            setType,
            guests: 0,
        });
        setNumberOfNewTables(prev => prev -= 1);
        setTables((prev) => {
            const newTables = [...prev]
            newTables.splice(index, 1)
            return newTables
        })
    };

    const handleSetTable = async (model: typeof values) => {

        await update({
            cruiseExternalId: CRUISE_ID,
            restaurantId: restaurantGuid,
            tablesToUpdate: model.tablesToAdd.map(t => {
                const isTable = data.tables.find(table => table.number === Number(t.number));
                return {
                    id: isTable?.guid,
                    number: Number(t.number),
                    numberSeats: Number(t.numberSeats),
                    isCloseRegistration: t.isCloseRegistration === "Закрытая"
                };

            })
        });

        setActiveTable({numberOfTable: ""});
        reset();
        if (onClose) {
            onClose();
        }
    };
    const setTablesToAdd = () => {
        if (values.tablesToAdd.find(t => String(t.number) === activeTable.numberOfTable) ) return;
        if (values.tablesToAdd[values.tablesToAdd.length - 1].number === 0) {
            values.tablesToAdd[values.tablesToAdd.length - 1] = {
                number: activeTable.numberOfTable,
                numberSeats: numberSeats,
                isCloseRegistration: type,
                numberOccupied: 0
            };
            setTables([activeTable])
            return;
        }
        setNumberOfNewTables(prev => prev += 1);
        addListItem('tablesToAdd', {
            number: activeTable.numberOfTable,
            numberSeats: numberSeats,
            isCloseRegistration: type,
            numberOccupied: 0
        });
        setTables(prev => [...prev, activeTable])
    };

    useEffect(() => {
        setTablesToAdd();
    }, [activeTable, numberSeats, type, data]);

    useEffect(() => {
        if (values.tablesToAdd) {
            values.tablesToAdd.forEach(t => (
                drawSeats({
                        table: {
                            ...t,
                            number: t.number,
                            numberSeats: t.numberSeats,
                            numberOccupied: 0,
                            isCloseRegistration: type,
                        },
                        setActiveTable,
                        setSeats: setNumberSeats,
                        setType,
                        guests: 0,
                        isActive: true
                    }
                )
            ));
        }
    }, [values.tablesToAdd, activeTable]);


    useEffect(() => {
        setPrevActiveTable(activeTable);
    }, [activeTable]);

    useEffect(() => {
        let disabledFlag = true
        for (const item of values.tablesToAdd){
            if(item.numberSeats == '0' || item.number == '0'){
                disabledFlag = false
                break
            }
        }
        setDisableButton(!(restaurantGuid && disabledFlag))

    }, [restaurantGuid, values, activeTable])

    return (
        <Group direction="column">
            <form onSubmit={onSubmit((handleSetTable))} className={style.container}>
                <Select data={restaurant}
                        rightSection={<ArrowIcon/>}
                        styles={{rightSection: {pointerEvents: 'none'}}}
                        style={{width: 430}}
                        variant="filled"
                        label="Ресторан"
                        placeholder="Выберите ресторан"
                        value={restaurantGuid}
                        onChange={(e) => changeRestrauntHandler(e)}
                />
                <Group mb="lg">
                    {tables.map((t, index) => (
                        <Group direction="row" key={t.numberOfTable}>
                            <Select data={[String(values.tablesToAdd[index].number)]}
                                //rightSection={<ArrowIcon/>}
                                    styles={{
                                        rightSection: {
                                            pointerEvents: 'none',
                                            display: "none"
                                        },
                                    }}
                                    style={{width: 100}}
                                    variant="filled"
                                    label="Номер стола"
                                    selectOnBlur
                                    placeholder="№"
                                    disabled={true}
                                    {...getListInputProps('tablesToAdd', index, 'number')}

                            />
                            <Select data={["Открытая", "Закрытая"]}
                                    rightSection={<ArrowIcon/>}
                                    styles={{rightSection: {pointerEvents: 'none'}}}
                                    style={{width: 200}}
                                    variant="filled"
                                    label="Тип регистрации"
                                    placeholder="Открытая/Закрытая"
                                    {...getListInputProps('tablesToAdd', index, 'isCloseRegistration')}
                            />
                            <Select data={t.isWideTable ? wideTableCapacity : smallTableCapacity}
                                    rightSection={<ArrowIcon/>}
                                    styles={{rightSection: {pointerEvents: 'none'}}}
                                    style={{width: 100}}
                                    variant="filled"
                                    label="Вместимость"
                                    placeholder="1"
                                    {...getListInputProps('tablesToAdd', index, 'numberSeats')}
                            />
                            {numberOfNewTables > 1 && (
                                <ActionIcon
                                    onClick={() => deleteTable(
                                        index,
                                        getListInputProps('tablesToAdd', index, 'number').value
                                    )}
                                >
                                    <Trash
                                        size={24}
                                        strokeWidth={1}
                                        color="red"
                                    />
                                </ActionIcon>
                            )}
                        </Group>
                    ))}
                </Group>
                <div className={style.schemes}>
                    <LoadingOverlay visible={false} loaderProps={{size: 'lg', variant: 'dots'}}/>
                    {isLoading
                        ? <Loader size="sm" variant="dots" style={{width: 50}}/>
                        : <Schemes svg={data?.schemaSvg}
                                   tables={data?.tables || []}
                                   setActiveTable={setActiveTable}
                                   setType={setType}
                                   setSeats={setNumberSeats}
                        />
                    }
                </div>
                <Group
                    noWrap
                    direction="row"
                    position="right"
                >
                    <ButtonCustom width={"sm"} appearance="ghost" onClick={onClose}>
                        Отмена
                    </ButtonCustom>
                    <ButtonCustom width="md"
                                  type="submit"
                                  appearance="secondary"
                                  disabled={isDisableButton}>
                        Применить
                    </ButtonCustom>
                </Group>
            </form>
        </Group>
    );
};

export default Tables;