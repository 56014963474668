import React, {Fragment, useState} from 'react';
import {ActionIcon, Group, Loader, Tabs, Text, } from "@mantine/core";
import style from "./Menu.module.css";
import {ReactComponent as AddTabs} from "../../../assets/svg/addTabs.svg";
import {ReactComponent as DownloadIcon} from "../../../assets/svg/downloadIcon.svg";
import {ReactComponent as CloseIcon} from "../../../assets/svg/closeIcon.svg";
import MenuList from "./MenuList/MenuList";
import AddTabMenu from './MenuModals/AddTabMenu/AddTabMenu';
import { useAddMenuFileMutation, useAddMenuTabMutation, useDeleteMenuMutation, useGetMenuByDateQuery, useGetMenuTabsQuery } from '../../../redux/apiSlice/_restaurantSlice';
import {Dropzone, DropzoneStatus} from "@mantine/dropzone";
import {Calendar} from '@mantine/dates';
import dayjs from 'dayjs';
import {showNotification} from "@mantine/notifications";
import {CircleCheck, FaceIdError} from "tabler-icons-react";


const Restaurant = ({...props}: any) => {

    const [openedAddTab, setOpenedAddTab] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);

    const [tabName, setTabName]  = useState("");

    const {data: BreakfastData = []} = useGetMenuTabsQuery(null);

    const onChangeTab = (active: number) => {
        setActiveTab(active);
    };

    const [addRestaurantTab] = useAddMenuTabMutation();

    const handleAddTab = async () => {
        await addRestaurantTab({
            name: tabName
        }).unwrap();
    };

    const CRUISE_ID = localStorage.getItem("cruise");

    const [value, setValue] = useState<Date | null>(new Date());
    const {data: menuData = {id: '', url: '', path: ''}, isFetching, isSuccess} = useGetMenuByDateQuery({
        date: dayjs(value).format("YYYY-MM-DD"),
        cruiseId: CRUISE_ID
    });
    const [deleteMenu, {isLoading: isDeleteMenu, isError}] = useDeleteMenuMutation();
    const [addMenu, {isLoading: isAddMenu}] = useAddMenuFileMutation();


    const dropzoneChildren = (status: DropzoneStatus) => (
        <ActionIcon size={40}>
            <DownloadIcon/>
        </ActionIcon>
    );

    const handleDeleteMenu = async () => {
        await deleteMenu({date: dayjs(value).format("YYYY-MM-DD")});
        isError
            ? showNotification({
                title: "Ошибка",
                message: "Произошла ошибка при удалении",
                color: 'red',
                icon: <FaceIdError />,
            })
            :  showNotification({
                title: "",
                message: "Файл удален",
                color:'green',
                icon: <CircleCheck />,
            });
    };

    const handleAddDaySchedule = async (file: any) => {

        const formData = new FormData();
        formData.append('file', file[0]);
        await addMenu({
            body: formData,
            date: dayjs(value).format("YYYY-MM-DD"),
            cruiseId: CRUISE_ID
        });

        isAddMenu
            ? showNotification({
                title: "Ошибка",
                message: "Не удалось загрузить файл",
                color: 'red',
                icon: <FaceIdError />,
            })
            :  showNotification({
                title: "",
                message: "Файл загружен",
                color:'green',
                icon: <CircleCheck />,
            });
    };

    return (
        <div className={style.restaurants} {...props}>
            <Fragment>
                <AddTabMenu opened={openedAddTab}
                                onClose={() => setOpenedAddTab(false)}
                                setTabName={setTabName}
                                handleAddTab={handleAddTab}
                />
                    <Text className={style.title}>Меню</Text>
                    <Group className={style.programDayContent}>
                        <Group direction="column" spacing={30} position="left">
                            <Group direction={"row"}>
                                <Dropzone
                                    maxSize={30000000000}
                                    accept={['.pdf']}
                                    loading={isAddMenu}
                                    onDrop={(files) => handleAddDaySchedule(files)}
                                    styles={{
                                        root: {padding: 0, border: "none"}
                                    }}
                                >
                                    {(status) => dropzoneChildren(status)}
                                </Dropzone>
                                <Text size="md" className={style.programDayTextDownload}>
                                    Загрузить PDF файл
                                </Text>
                            </Group>
                            <Group direction="column" spacing="md">
                                {isFetching
                                    ? <Loader size="sm" variant="dots"/>
                                    : isSuccess &&
                                       <Group direction="row" spacing={32}>
                                            <Text component={"a"}  target="_blank" download href={menuData.url} className={style.programDayTextLink}>
                                                {menuData.path}
                                            </Text>
                                            <ActionIcon onClick={() => handleDeleteMenu()}>
                                                <CloseIcon/>
                                            </ActionIcon>
                                        </Group>
                                }
                            </Group>
                        </Group>
                        <Calendar
                            size={"sm"}
                            className={style.programDayCalendar}
                            value={value}
                            onChange={setValue}
                            locale="ru"
                        />
                    </Group>
                    <Fragment>
                        <Text className={style.title}>Завтрак в каюту</Text>
                        {/* {!BreakfastData.length && (
                            <button disabled className={style.addTabButton}>
                                <div onClick={() => {
                                    setOpenedAddTab(true);
                                }} className={style.addTab}>
                                    <AddTabs style={{marginRight: 8}}/>
                                    Создать вкладку
                                </div>

                            </button>
                        )} */}
                        {!!BreakfastData.length && (
                            <Tabs active={activeTab} onTabChange={onChangeTab} className={style.tabs}>
                                {BreakfastData.map((tab: any) => (
                                    <Tabs.Tab label={tab.name} tabKey={tab.id} key={tab.id}>
                                        <MenuList tabName={tab.name}
                                                        dishes={tab.dishes}
                                                        tabId={tab.id}
                                        />
                                    </Tabs.Tab>
                                ))}
                                {/* <button disabled className={style.addTabButton}>
                                    <div onClick={() => {
                                        setOpenedAddTab(true);
                                    }} className={style.addTab}>
                                        <AddTabs style={{marginRight: 8}}/>
                                        Создать вкладку
                                    </div>

                                </button> */}
                            </Tabs>
                        )}
                    </Fragment>
            </Fragment>
        </div>
    );
};

export default Restaurant;

