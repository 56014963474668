import {ITables} from "../types/IRegistration";
import {RequestType} from "../types/IRequest";
import {ExcursionType} from "../types/IExcursion";


export const getUniq = (model: ITables[]) => {
    return model.filter((table: ITables, index: number, arr) =>
            index === arr.findIndex((t: ITables) => {
                    if (t.number === table.number && t.numberSeats !== 0) {
                        return  t.numberSeats = table.numberSeats;
                    }
                }
            )
    );
};


export const getUpdateUniq = (model: any) => {
    return model.filter((table: any, index: number, arr: any) =>
            index === arr.findIndex((t: any) => {
                    if (t.id === table.id && t.numberSeats !== 0) {
                        return  t.numberSeats = table.numberSeats;
                    }
                }
            )
    );
};

export const getCsvWithCorrectDate = (model: RequestType[] | ExcursionType[]): any => {
    return model.map(item => {
        const newItem = Object.assign({}, item)
        newItem.date = new Date(newItem.date).toLocaleString("ru", {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        })
        return newItem
    })
}

export const clearNewRect = (rects: HTMLCollectionOf<Element>, endIndex: number) => {
    for(let i = endIndex-1; i >= 0; i--){
        if(rects?.[i] !== undefined){
            rects[i].remove()
        }
    }
}
export const resetNotActivePaths = () => {
    const notActiveMenu: NodeListOf<HTMLElement> = document.querySelectorAll('g[id^="Frame "]')
    notActiveMenu.forEach((element: HTMLElement) => {
        element.removeAttribute("style")
        element.onclick = null;
        const rect = element.getElementsByTagName("rect")
        rect[0].setAttribute("style", 'fill="white"')
    })
}