import React, {useState} from 'react';
import {ReviewsProps} from "./_Reviews.props";
import {Pagination, Text, Group, ActionIcon, Select} from '@mantine/core';
import {ButtonCustom} from "../../components";
import style from './_Reviews.module.css';
import {ReactComponent as FilterIcon} from '../../assets/svg/filterIcon.svg';
import {ReactComponent as ArrowIcon} from '../../assets/svg/arrow.svg';
import {ReviewsFilterType} from '../../types/IReviews';
import {ListStub} from "../../common";
import {CSVLink} from "react-csv";
import ReviewsFilter from './ReviewsFilters/ReviewsFilter';
import ReviewsList from './ReviewsList/ReviewsList';
import {useFetchReviewQuery, useGetReviewsCsvQuery, } from "../../redux/apiSlice/_reviewSlice";
import {ReactComponent as RefreshIcon} from "../../assets/svg/refreshIcon.svg";
import { ICruise } from '../../types/IAuth';
import { useGetRelativeCruiseQuery } from '../../redux/apiSlice/_authSlice';
import { Checkbox } from '@mantine/core';


export const Reviews = ({...props}: ReviewsProps): JSX.Element => {

    // const [openedDialog, setOpenedDialog] = useState(false);
    // const [activePage, setPage] = useState<number>(1);
    // const [pageSize, setSetPageSize] = useState<number>(10);
    // const [filter, setFilter] = useState<ReviewsFilterType>({});

    const CRUISE_ID = localStorage.getItem("cruise");

    const {data: allCruises = []} = useGetRelativeCruiseQuery(CRUISE_ID ? CRUISE_ID : "");
    const cruiseData = allCruises.map((s: ICruise) => ({
        value: String(s.cruiseId),
        label: `${s.dateStart} - ${s.dateEnd}`
    }));

    const [cruise, setCruise] = useState(CRUISE_ID);
    const [isAll, setIsAll] = useState<boolean>(false);

    const {data: reviews = {reviewsCsv: ''}, isLoading} = useGetReviewsCsvQuery({cruiseId: cruise, shouldIncludeRelativeCruises: isAll});


    return (
        <>
            <div {...props} className={style.application}>
                <Group direction="column" spacing={32}>
                    <Text weight={500} className={style.headerTitle}>
                            Выберите круиз
                    </Text>
                    <Select
                        rightSection={<ArrowIcon/>}
                        variant="filled"
                        styles={{rightSection: {pointerEvents: 'none'}}}
                        placeholder='Выберите круиз'
                        data={cruiseData}
                        label="Круиз"
                        value={cruise}
                        onChange={setCruise}
                    />
                    <Checkbox checked={isAll} onChange={(event) => setIsAll(event.currentTarget.checked)} label="Отдавать родительский и все дочерние">

                    </Checkbox>
                    <Group position="right" style={{ width: "100%" }}>
                        <CSVLink filename='Отзывы' data={reviews.reviewsCsv}>
                            <ButtonCustom width={"md"} appearance="secondary" type="submit" disabled={isLoading}>
                                Скачать
                            </ButtonCustom>
                        </CSVLink>
                    </Group>
                </Group>
            </div>
        </>
    );
};

